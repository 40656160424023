




import React from "react";
import { Link, graphql } from "gatsby";
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";



const ButtonBackToMainPage = () => (<Link to="/"><Button startIcon={<ArrowBackRoundedIcon/>} size="small">powrót do strony głównej</Button></Link>)

export default ButtonBackToMainPage;

